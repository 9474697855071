import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/tr";
import parse from "html-react-parser";
import { convert } from "html-to-text";
import "./AgreementPage.css";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";

const AgreementPage = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const agreementRef = useRef(null);
  const [detailList, setDetailList] = useState(null);
  const [debyData, setDebyData] = useState(null);
  const [user, setUser] = useState(null);
  const [agreement, setAgreement] = useState(null);
    const [verifyData, setVerifyData] = useState(null);
  
  // Yeni state'ler
  const [isVerified, setIsVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (id) {
        console.log('id', id)
      fetchInitialData();
    }
  }, [id]);

  const fetchInitialData = async () => {
    try {
        setLoading(true);
      const detailResponse = await fetch(`https://api.duguntakibi.com.tr/v1/registration/agreement/verification?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const detailData = await detailResponse.json();
      setCountdown(300);
      setPhone(detailData);
    } catch (error) {
      console.error("Veri yüklenirken hata oluştu:", error);
      setError("Bilgiler yüklenirken bir hata oluştu");
    }
    finally {
        setLoading(false);
      }
  };


  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown(current => current - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  // Sayacı formatla
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleOTPChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; // Sadece rakam girişine izin ver

    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);

    // Otomatik olarak sonraki input'a geç
    if (value !== '' && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    // Backspace tuşuna basıldığında önceki input'a geç
    if (e.key === 'Backspace' && index > 0 && verificationCode[index] === '') {
      inputRefs[index - 1].current.focus();
    }
  };

  const getCompleteVerificationCode = () => verificationCode.join('');

  const verifyCode = async () => {
    try {
      setLoading(true);
      const completeCode = getCompleteVerificationCode();
      const verifyResponse = await fetch(`https://api.duguntakibi.com.tr/v1/registration/agreement/verificationCode?id=${id.toLocaleString()}&code=${completeCode.toLocaleString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
        const verifyData = await verifyResponse.json();
        if(verifyData){
            const verifyLog = await fetch(`https://api.duguntakibi.com.tr/v1/registration/agreement/verificationLog?id=${id.toLocaleString()}&code=${completeCode.toLocaleString()}&success=true`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                }
              });
        }
        setVerifyData(verifyData)
      setIsVerified(true);
      
      

    } catch (error) {
        const completeCode = getCompleteVerificationCode();
        const verifyLog = await fetch(`https://api.duguntakibi.com.tr/v1/registration/agreement/verificationLog?id=${id.toLocaleString()}&code=${completeCode.toLocaleString()}&success=false`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
      setError("Doğrulama kodu hatalı");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="wpo-contact-form-area section-padding" style={{ background: '#f8f9fa', minHeight: '100vh' }}>
        {!isVerified ? (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card p-4">
                  <h3 className="text-center mb-4" style={{fontFamily: 'Poppins'}}>Anlaşma Sorgulama</h3>
                  {error && <div className="alert alert-danger text-center">{error}</div>}
                  <div className="form-group mb-3 d-flex flex-column align-items-center justify-content-center">
                    <label className="mb-3 text-center"><span className="fw-bold text-primary">{phone ? "0"+phone: ""}</span>{phone ? " nolu " : ""} Telefon Numaranıza Gelen Doğrulama Kodunu Giriniz.</label>
                    <div className="otp-container">
                      {[0, 1, 2, 3].map((index) => (
                        <input
                          key={index}
                          ref={inputRefs[index]}
                          type="text"
                          maxLength={1}
                          className="otp-input"
                          value={verificationCode[index]}
                          onChange={(e) => handleOTPChange(index, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          autoComplete="off"
                        />
                      ))}
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100"
                    onClick={verifyCode}
                    disabled={loading || verificationCode.join('').length !== 4}
                  >
                    {loading ? 'Doğrulanıyor...' : 'Doğrula'}
                  </button>
                  <button
                    className="btn btn-link w-100 mt-2"
                    onClick={() => {
                      setVerificationCode(['', '', '', '']);
                      fetchInitialData();
                    }}
                    disabled={loading || countdown > 0}
                  >
                    {countdown > 0 
                      ? `Yeni kod gönderebilmek için ${formatTime(countdown)} bekleyin` 
                      : 'Yeni Kod Gönder'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div ref={agreementRef} className="agreement-container print-only">
            {/* Firma Logo & Başlık */}
            <div className="text-center mb-5">
              {user?.company?.company_logo && (
                <img
                  src={user?.company?.company_logo}
                  alt="Logo"
                  className="img-fluid mb-4"
                  style={{ maxHeight: '180px', objectFit: 'contain' }}
                />
              )}
              <div className="section-title text-center">
                <h2 className="mb-0 fw-bold text-primary" 
                    style={{ 
                      fontSize: '2rem', 
                      fontFamily: 'Poppins'
                    }}>
                  ORGANİZASYON ANLAŞMA BİLGİLERİ
                </h2>
              </div>
            </div>

            {/* Müşteri Bilgileri */}
            <div className="row mb-5">
              <div className="col-12">
                <div className="card-custom border p-4 h-100 bg-gradient-light avoid-break">
                  <h4 className="section-title fw-bold text-primary" 
                      style={{ 
                        fontSize: '1.8rem',
                        fontFamily: 'Poppins, sans-serif'
                      }}>
                    Müşteri Bilgileri
                  </h4>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <strong className="text-primary">Adı Soyadı:</strong>
                        <div className="mt-1">{verifyData?.name_lastname}</div>
                      </div>
                      <div className="mb-3">
                        <strong className="text-primary">Telefon:</strong>
                        <div className="mt-1">{"0"+verifyData?.phone}</div>
                      </div>
                      <div className="mb-3">
                        <strong className="text-primary">Organizasyon Tarihi:</strong>
                        <div className="mt-1">
                          {moment(verifyData?.date_of_organisation).format("DD MMMM YYYY")}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <strong className="text-primary">Organizasyon Mekanı:</strong>
                        <div className="mt-1">{verifyData?.areas_name}</div>
                      </div>
                      <div className="mb-3">
                        <strong className="text-primary">Organizasyon Türü:</strong>
                        <div className="mt-1">{verifyData?.organisation_type_name} Organizasyonu</div>
                      </div>
                      <div className="mb-3">
                        <strong className="text-primary">Anlaşma Tutarı:</strong>
                        <div className="mt-1 fw-bold">{verifyData?.organisation_fee?.toLocaleString("tr-TR")}₺</div>
                      </div>
                    </div>
                  </div>
                  {verifyData?.notes ? <div className="mt-3">
                    <strong className="text-primary">Notlar:</strong>
                    <div className="mt-2 p-3 bg-white rounded">{verifyData?.notes}</div>
                  </div> : null}
                </div>
              </div>
            </div>

            {/* Ödeme Detayları */}
            <div className="card-custom border p-4 mb-5 bg-gradient-light avoid-break">
              <h4 className="section-title fw-bold text-primary" 
                  style={{ 
                    fontSize: '1.8rem',
                    fontFamily: 'Poppins, sans-serif'
                  }}>
                Ödeme Detayları
              </h4>
              <div className="payment-details">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="fw-bold fs-2">Anlaşılan Tutar:</div>
                  <div className="text-end text-primary fw-bold fs-2">
                    {verifyData?.organisation_fee?.toLocaleString(
                      "tr-TR"
                    )}{" "}
                    ₺
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="fw-bold fs-2">Ödenen:</div>
                  <div className="text-end text-success fw-bold fs-2">
                    {verifyData?.total_payment.toLocaleString(
                      "tr-TR"
                    )}{" "}
                    ₺
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-bold fs-2">Kalan Borç:</div>
                  <div className="text-end text-danger fw-bold fs-2">
                    {verifyData?.remaining_payment.toLocaleString(
                      "tr-TR"
                    )} {" "}
                    ₺
                  </div>
                </div>
                <div className="mt-3 text-end">
                  {verifyData?.remaining_payment > 0 && (
                    moment(verifyData?.date_of_organisation).subtract(10, "days").isBefore(moment()) ? (
                      <small className="text-danger">
                        Kalan borç en geç{" "}
                        <strong>
                          {moment(verifyData?.date_of_organisation)
                            .subtract(10, "days")
                            .format("DD MMMM YYYY")}
                        </strong>{" "}
                        tarihine kadar ödenmiş olmalıydı. Lütfen organizasyon firması ile iletişime geçiniz.
                      </small>
                    ) : (
                      <small>
                        Kalan borç en geç{" "}
                        <strong>
                          {moment(verifyData?.date_of_organisation)
                            .subtract(10, "days")
                            .format("DD MMMM YYYY")}
                        </strong>{" "}
                        tarihine kadar ödenmiş olmalıdır.
                      </small>
                    )
                  )}
                </div>
              </div>
            </div>

            {/* Sözleşme Maddeleri */}
            <div className="container pt-4 pb-5 page-break">
              <h4 className="section-title text-center fw-bold text-primary" 
                  style={{ 
                    fontSize: '1.8rem',
                    fontFamily: 'Poppins, sans-serif'
                  }}>
                Anlaşma Maddeleri
              </h4>
              <div className="agreement-text bg-white p-5 rounded avoid-break">
                <p>
                  <strong>DÜĞÜN ORGANİZASYONU HİZMET SÖZLEŞMESİ</strong>
                </p>
                <p>
                  <strong>Taraflar</strong>
                </p>
                <p>
                  Bu sözleşme, belgenin sonundaki imzaların bulunduğu kısımdaki
                  tarihte imzalanmış olup, bir tarafta Feyza Organizasyon
                  ("Organizatör") ile diğer tarafta belgenin sonundaki imzaların
                  bulunduğu kısımdaki müşteri adı ("Müşteri") arasında
                  yapılmıştır.
                </p>
                <p>
                  <strong>1. Konu</strong>
                </p>
                <p>
                  Bu sözleşmenin konusu, Müşteri tarafından belirlenen tarih ve
                  yerde düzenlenecek düğün organizasyon hizmetinin verilmesi ve
                  tarafların bu hizmet ile ilgili hak ve yükümlülüklerinin
                  belirlenmesidir.
                </p>
                <p>
                  <strong>2. Hizmetler</strong>
                </p>
                <p>
                  Organizatör, Müşteri'ye sözleşme notlarında belirtilen
                  hizmetleri sunacaktır.
                </p>
                <p>
                  <strong>3. Organizasyon Tarihi ve Yeri</strong>
                </p>
                <p>
                  Organizasyon, sözleşmede belirtilen tarihte ve mekanda
                  gerçekleştirilecektir.
                </p>
                <p>
                  <strong>4. Hizmet Bedeli ve Ödeme Koşulları</strong>
                </p>
                <p>
                  4.1. Organizasyon hizmetinin toplam bedeli sözleşmede belirtilen
                  tutar olup, bu bedel aşağıdaki takvime göre ödenecektir:
                </p>
                <p>
                  Sözleşme imzalandığında: Toplam bedelin %50'si peşinat olarak.
                </p>
                <p>
                  Organizasyon tarihinden <strong>10</strong> gün öncesine kadar
                  kalan tutarın tamamı.
                </p>
                <p>4.2. Ödeme, banka havalesi veya nakit olarak yapılabilir.</p>
                <p>
                  <strong>5. İptal ve Değişiklikler</strong>
                </p>
                <p>
                  5.1. Müşteri tarafından organizasyon tarihine{" "}
                  <strong>90</strong> gün kala yapılan iptallerde, peşinat ödemesi
                  iade edilmez.
                </p>
                <p>
                  5.2. Taraflardan herhangi biri organizasyon tarihi, saati veya
                  mekânında bir değişiklik yapmak isterse, bu değişiklik, diğer
                  tarafın yazılı onayına tabidir.
                </p>
                <p>
                  <strong>6. Tarafların Yükümlülükleri</strong>
                </p>
                <p>
                  6.1. Organizatör, hizmetlerin zamanında ve sözleşmeye uygun
                  olarak yerine getirilmesini taahhüt eder.
                </p>
                <p>
                  6.2. Müşteri, organizasyon öncesinde gerekli bilgilendirmeleri
                  ve ödemeleri zamanında yapmakla yükümlüdür.
                </p>
                <p>
                  <strong>7. Mücbir Sebepler</strong>
                </p>
                <p>
                  Taraflardan herhangi biri, mücbir sebep nedeniyle
                  yükümlülüklerini yerine getiremezse, bu durumdan sorumlu
                  tutulamaz. Mücbir sebep; deprem, sel, yangın, salgın hastalık
                  gibi tarafların kontrolü dışında gelişen olayları kapsar.
                </p>
                <p>
                  <strong>8. Anlaşmazlıkların Çözümü</strong>
                </p>
                <p>
                  Bu sözleşmeden doğan anlaşmazlıklar, öncelikle taraflar arasında
                  dostane yollarla çözülmeye çalışılacak, çözülememesi halinde
                  Erzurum mahkemeleri ve icra daireleri yetkilidir.
                </p>
                <p>
                  <strong>9. Süre ve Yürürlüke Giriş</strong>
                </p>
                <p>
                  Bu sözleşme, tarafların imzalaması ile yürürlüğe girer ve
                  organizasyon hizmetinin tamamlanmasıyla sona erer.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementPage;
