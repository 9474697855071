import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/2.png'
import sImg2 from '../../images/story/1.png'
import sImg3 from '../../images/story/3.png'

import shape from '../../images/story/shape.png'


const Storys = [
    {
        sImg: sImg1,
        title: 'Feyza Organizasyon farkı ile',
        date: '12 Feb 2016',
        description: 'Uzun yıllara dayanan sahne / dekor ve eğlence tecrübemizle birlikte binlerce organizasyona imza attık. ',
    },
    {
        sImg: sImg2,
        title: 'Sizlerin yanınızdayız.',
        date: '23 Apr 2016',
        description: 'En güzel anınızda sizlerin yanında olan 10 kişilik ekibimizle hiçbir zaman heyecanımızı kaybetmeden organizasyonunuzu en iyi şekilde geçirmeniz için yanınızdayız.',
    },
    {
        sImg: sImg3,
        title: 'Sürekli yenileniyoruz.',
        date: '10 Mar 2016',
        description: 'Yıl içerisinde sürekli yeni tasarımlar ve yeni fikirler üreten kadromuz ile özel tasarım kaftan modellerimiz veya sahne taht dekorlarımızla sürekli yenileniyoruz.',
    }

]


const StorySection = (props) => {
    return (
        <section className={`wpo-story-section section-padding ${props.stClass}`}>
            <div className="container-fluid">
                <SectionTitle subTitle={'Feyza Organizasyon'} MainTitle={'16 yıllık tecrübesİyle sİzlerle..'} />
                <div className="wpo-story-wrap">
                    {
                        Storys.map((story, st) => (
                            <div className="wpo-story-item" key={st}>
                                <div className="wpo-story-img-wrap">
                                    <div className="wpo-story-img">
                                        <img src={story.sImg} alt="" />
                                    </div>
                                    <div className="wpo-img-shape">
                                        <img src={shape} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <div className="wpo-story-content-inner">
                                        <h2>{story.title}</h2>
                                        {/* <span>{story.date}</span> */}
                                        <p>{story.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>
        </section>
    )
}

export default StorySection;