import React, { Fragment } from "react";
import { connect } from "react-redux";
// import Navbar2 from "../components/Navbar2/Navbar2";
import Navbar23 from "../components/Navbar23/Navbar23";
import PageTitle from "../components/pagetitle/PageTitle";
import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Scrollbar from "../components/scrollbar/scrollbar";
import { removeFromWishList, addToCart } from "../store/actions/action";
import ReactJotformEmbed from "react-jotform-embed";
 
  


const RandevuAl = (props) => {
  const { wishs } = props;

  return (
    <Fragment>
      {/* <Navbar2 /> */}
      <Navbar23 />
      {/* <PageTitle pageTitle={"Randevu Al"} pagesub={"Randevu Al"} /> */}
          <div style={{ marginTop: "-26vh" }}>
              
      <ReactJotformEmbed src="https://form.jotform.com/203485045939968" />

      </div>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    wishs: state.wishList.w_list,
  };
};
export default connect(mapStateToProps, { removeFromWishList, addToCart })(
  RandevuAl
);
